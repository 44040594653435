//
//testimonial.scss
//

.testi-box {
    position: relative;
    max-width: 630px;
    border: none;
    margin: 0 auto;
    text-align: center;
}

.swiper-pagination{
    .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        background-color: var(--#{$prefix}primary);
        opacity: 0.5;
        border-radius: 8px;
        transition: all 0.5s ease;
        &.swiper-pagination-bullet-active{
            opacity: 1;
            width: 26px;
        }
    }
}