.a1 {
  width: 50% " aria-valuenow=" 50 " aria-valuemin=" 0 " aria-valuemax=" 100
    ";
}
.a2
{
    width: 20%" aria-valuenow= "20" aria-valuemin= "0" aria-valuemax=
    "100";
}
.a3 {
  width: 40% " aria-valuenow=" 40 " aria-valuemin=" 0 " aria-valuemax=" 100
    ";
}
.a4
{
    width: 80%" aria-valuenow= "80" aria-valuemin= "0" aria-valuemax=
    "100";
}
.a5 {
  width: 100% " aria-valuenow=" 100 " aria-valuemin=" 0 " aria-valuemax=" 100
    ";
}
.a6
{
    width: 25rem;
}
.a7
{
    width: 60%" aria-valuenow= "60"
    aria-valuemin= "0" aria-valuemax= "100";
}
.toggled {
  overflow: visible;
  width: 6.5rem !important;
}
.toggled1 {
  width: 0 !important;
  overflow: hidden;
}

#wrapper {
  margin-top: 110px;
}

.hover-effect:hover {
  background-color: #e0e0e0;
  color: #000;
  cursor: pointer;
}

.icon {
  margin-left: 25px;
}
.side-border {
  border-color: rgb(221, 217, 217);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
}
.labeltype {
  font-weight: 500;
}

.avatar-container {
  position: relative;
  .avatar-logo {
    max-width: 80px;
    min-height: 80px;
    
  }
  .remove-button {
    position: absolute;
    top: -10px;
    left: 70px;
    background-color: #3b3535;
    border-radius: 50%;
    color: #fff;
    font-size: 10px;
    cursor: pointer;
    padding: 0 5px;
  }
}
/* Custom styles for multiline text */
.custom-heading {
  font-size: 2rem; /* Default font size */
  margin-top: 1.5rem; /* Adjust as needed for spacing */
  white-space: normal; /* Allow multiline text */
}

/* Adjust font size for small screens */
@media (max-width: 570px) {
  .custom-heading {
    font-size: 1rem;
    width: 200px;
  }
}
@media (min-width: 577px) and (max-width: 768px) {
  .custom-heading{
    font-size: 1.75rem;
    width: 300px;
  }
}
@media (min-width: 768px) and (max-width: 890px) {
  .custom-heading{
    font-size: 1.50rem;
    width: 400px;
  }
}

/* Add more media queries as needed for different screen sizes */

/* 
@media (max-width: 576px) {
  .custom-heading {
    font-size: 0.9rem;
  }
} */

/* table data css */

.table thead th {
  height: 40px; /* Set your desired height for the table header */
}

.table tbody {
  max-height: 300px; /* Set your desired max height for the table body */
  overflow-y: auto;
}

.table {
  width: 100%; /* Ensure the table takes up the full width of its container */
}
.custom-table {
  border-collapse: collapse;
  
width: 100%;


}
.custom-table {
  border-collapse: collapse;
  width: 100%;
}

.custom-table th, .custom-table td {
  border: 1px solid #6d6969;
  padding: 8px;
  text-align:center;
}

.custom-table th {
  background-color: #f2f2f2;
}

/* Update page edit icon */
.relative {
  position: relative;
}

.absolute {
  position: absolute;
  top: 50%;
  right: 10px; 
  transform: translateY(-50%);
  cursor: pointer;
  color: #333; 
}

@media (max-width: 2560px) {
  .fa-times-icon {
    top: -10px;
    right: 5px;
    padding: 1px;
  }
}

@media (max-width: 768px) {
  .fa-times-icon {
    top: -10px;
    right: 5px;
    padding: 1px; 
  }
}

@media (max-width: 425px) {
  .fa-times-icon {
    top: -10px;
    right: 5px; 
    padding: 1px; 
  }
}
