//
//npagination.scss
//


.pagination {
    .page-item {
        .page-link {
            border-radius: 40px;
            height: 45px;
            width: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 4px;
            &:hover, &:focus {
                color: var(--#{$prefix}pagination-hover-color);
            }
        }
        &.active {
            .page-link{
                &:hover, &:focus{
                   color: $white;
                }
            }
        }
    }
}

