//
//pricing.scss
//

.pricing-details {
  .pricing-item {
    padding: 9px 0;
    i {
      width: 22px;
      height: 22px;
      line-height: 22px;
      display: inline-block;
      text-align: center;
      border-radius: 40px;
    }
  }
}

.pricing-box {
  border: none;
  transition: all 0.5s ease;

  .pricing-icon {
    position: absolute;
    top: -56px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    line-height: 5rem;
    border: 6px solid var(--#{$prefix}border-color-translucent);
    height: 90px;
    width: 90px;
    line-height: 96px;
  }

  &:hover {
    box-shadow: var(--#{$prefix}box-shadow);
    transform: translateY(-8px);
  }
}

.pricing-counter {
  background-image: url("../../../images/bg-counter.svg");
  background-size: cover;
  background-position: center;
  padding: 35px;
  border-radius: 20px;
}
