.a1 {
    width: 50%" aria-valuenow=" 50" aria-valuemin=" 0" aria-valuemax=" 100";

}

.a2 {
    width: 20%" aria-valuenow=" 20" aria-valuemin=" 0" aria-valuemax=" 100";

}

.a3 {
    width: 40%" aria-valuenow=" 40" aria-valuemin=" 0" aria-valuemax=" 100";

}

.a4 {
    width: 80%" aria-valuenow=" 80" aria-valuemin=" 0" aria-valuemax=" 100";

}

.a5 {
    width: 100%" aria-valuenow=" 100" aria-valuemin=" 0" aria-valuemax=" 100";

}

.a6 {
    width: 25rem;
}

.a7 {
    width: 60%" aria-valuenow=" 60" aria-valuemin=" 0" aria-valuemax=" 100";

}

.toggled {
    overflow: visible;
    width: 6.5rem !important;
}

.toggled1 {
    width: 0 !important;
    overflow: hidden;
}

#wrapper {
    margin-top: 110px;
}

.hover-effect:hover {
    background-color: #e0e0e0;
    color: #000;
    cursor: pointer;
}

.icon {
    margin-left: 25px;
}

.side-border {
    border-color: rgb(221, 217, 217);
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;
}

.social-link:hover {
    transform: scale(1.2);
    opacity: 0.6;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
