//
//authentication.scss
//

.bg-auth {
  padding: 40px 0;
  background-color: rgba(var(--#{$prefix}primary-rgb), 0.1);
  align-items: center;
  display: flex;
  min-height: 100vh;
  @media (max-width: 991.98px) {
    padding: 60px 0;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.auth-box {
  border: none;
  box-shadow: var(--#{$prefix}box-shadow);
  border-radius: 10px;
  overflow: hidden;
  .auth-content {
    background-color: var(--#{$prefix}primary-text);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .select_role {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;

      .company,
      .student {
        outline: none;
        border: 1px solid #ffff;
        color: #ffff;
        background-color: transparent;
        padding: 2px 15px;
        // transition: all 300ms;
      }

      .company {
        border-bottom-right-radius: 6px;
        border-top-right-radius: 6px;
      }
      .student {
        border-bottom-left-radius: 6px;
        border-top-left-radius: 6px;
      }
      .active {
        background-color: #ffff;
        color: #000;
      }
    }
  }
  .logo-dark {
    display: inline-block;
  }
  .logo-light {
    display: none;
  }
  .auth-form {
    .form-control {
      position: relative;
      color: $white;
      border-color: rgba($white, 0.1);
      background-color: rgba($white, 0.1);
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgba($white, 0.45);
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba($white, 0.45);
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: rgba($white, 0.45);
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: rgba($white, 0.45);
      }

      .options {
        color: #000;
        padding: 5px, 0;
      }
    }
    .password_control {
      position: relative;

      .password_visible_icon {
        position: absolute;
        top: 6px;
        // bottom: 0;
        right: 16px;
      }
    }
  }
}

/*****************dark-mode******************/

body[data-bs-theme="dark"] {
  .bg-auth {
    background-color: $gray-900;
  }
  .auth-box {
    .logo-light {
      display: inline-block;
    }
    .logo-dark {
      display: none;
    }
  }
}
