//
//service.scss
//

.service-box{
    border: none;
    transition: all 0.5s ease;
    &:hover {
        transform: translateY(-8px);
    }
}