//
//job-grid.scss
//

.job-grid-box{
    border-radius: 14px;
    transition: all 0.5s ease;
    .favorite-icon{
        position: absolute;
        right: 20px;
        top: 20px;
    }
    &:hover {
        transform: translateY(-10px);
    }
}